<template>
    <div class="login">
        <el-row>
            <!-- <el-col :span="12"> 
                <div class="left">
                    <img src="../assets/img/login_bg_left.png" alt="" />
                </div>
            </el-col> -->
            <!-- <el-col :span="12"> -->
            <el-col>    
                <div class="right">
                    <!-- <img src="../assets/img/login_bg_right.png" alt="" /> -->
                    <div class="login_block">
                        <h6>
                            <span class="welcome"> 欢迎登录 </span>
                        </h6>
                        <el-form
                            :model="loginForm"
                            :rules="rules"
                            ref="loginForm"
                        >
                            <p class="">账号</p>
                            <el-form-item prop="username" :error="errorMsg1">
                                <el-input
                                    type="text"
                                    v-model="loginForm.username"
                                    auto-complete="off"
                                    placeholder="请输入账号"
                                    class="icon13a"
                                ></el-input>
                            </el-form-item>
                            <p class="">密码</p>
                            <el-form-item prop="password" :error="errorMsg2">
                                <el-input
                                    type="password"
                                    v-model="loginForm.password"
                                    auto-complete="off"
                                    placeholder="请输入密码"
                                    class="icon13b"
                                ></el-input>
                            </el-form-item>
                            <el-form-item class="btn">
                                <el-button
                                    type="primary"
                                    @click="submitForm('loginForm')"
                                    class="button"
                                    >登 录</el-button
                                >
                            </el-form-item>
                        </el-form>

                        <div class="bottom_con">
                            <router-link class="" to=""
                                >沐源管理后台</router-link
                            >
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>

export default {
    data() {
        return {
            loginForm: {
                username: "",
                password: "",
            },
            rules: {
                username: [
                    { required: true, message: "请输入账号", trigger: "blur" },
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                ],
            },
            errorMsg1: "",
            errorMsg2: "",
            navShow: false,
            savePassword: "",
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const that = this;
                    let data = {
                        username: that.loginForm.username,
                        password: that.loginForm.password,
                    };
                    // return;
                    // 发送请求
                    this.$api.login(data).then((res) => {
                        if (res.data.msg == "用户名不存在！") {
                            that.errorMsg1 = res.data.msg;
                        }
                        if (res.data.msg == "密码不正确！") {
                            that.errorMsg2 = res.data.msg;
                        }
                        if (res.data.msg == "用户不存在") {
                            that.errorMsg1 = res.data.msg;
                        }
                        if (res.data.msg == "密码不正确！") {
                            that.errorMsg2 = res.data.msg;
                        }
                        if(res.data.msg == 'ok'){
                            this.$localStorage.setItem('userInfo',res.data.data,86400)
                            this.$store.commit('setUInfo',res.data.data)
                            console.log('111111')
                            this.getMenu()
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        getMenu(){
            let uinfo = this.$localStorage.getItem('userInfo')
            let data = {
                limit: 100,
                offset: 1,
                admin_id: uinfo._id
            }
            let that = this
            this.$api.getAuthAdmin(data).then((res) => {
                console.log('res', res)
                if (res.data.code == 0) {
                    let list = [], operation_auth = []
                    res.data.data.rows.map((item,index)=>{
                        if(item.auth==1){
                            list.push({
                                icon:item.menu_icon,
                                title:item.menu_name,
                                link:item.menu_addr
                            })
                        }
                        if(item.auth_status == 1){
                            operation_auth.push({
                                path:item.menu_addr,
                                auth_status:item.auth_status
                            })
                        }
                    })
                    console.log('list',list,res.data.data.rows)
                    that.$localStorage.setItem('menus',list)
                    that.$localStorage.setItem('operation_auth',operation_auth, 86400,true)
                    if(list){
                        that.$router.push(list[0].link)
                    }

                }
            })
            
        }
    },
};
</script>

<style lang="less" scoped>
.login {
    .left {
        position: relative;
        height: 100vh;
        background: linear-gradient(
            180deg,
            rgba(24, 90, 233, 0.63) 0%,
            #5183f0 100%
        );
        img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 100%;
            height: 697px;
            z-index: 999;
        }
    }
    .right {
        height: 100vh;
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 10%;
            left: -10%;

            width: 128%;
            height: 100%;
            z-index: 899;
        }
        .login_block {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            transform: translateX(-15%) translateY(-10%);

            z-index: 999;
            background: #ffffff;
            box-shadow: 0px 16px 21px 0px rgba(24, 90, 233, 0.63),
                0px 0px 3px 0px rgba(24, 90, 233, 0.13);
            border-radius: 8px;

            padding: 34px 39px 21px 35px;
            width: 358px;
            height: 383px;
            h6 {
                margin-bottom: 30px;
            }
            .welcome {
                position: relative;
                font-size: 24px;
                &::after {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 4px;
                    // background-color: #2185ee;
                    bottom: 2px;
                    left: 0;
                    opacity: 0.9;
                }
            }
            p {
                margin-bottom: 8px;
            }
            .btn {
                margin-top: 30px;
                margin-bottom: 10px;
                .button {
                    width: 100%;
                    background-color: #185ae9;
                    color: white;
                }
            }
            .bottom_con {
                font-size: 12px;
                text-align: center;
                a {
                    color: #999999;
                }
            }
        }
    }
}
</style>
